import ContentHeader from "@/components/Blocks/ContentHeader";
import styled from "styled-components";
import { Card } from "primereact/card";
import { useAuthStore } from "@/Stores/AuthStore";
import { Button } from "primereact/button";
import { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { useGetOrganisation } from "@/Service/Api/ApiHooks/Organisation/useGetOrganisation";
import { useRestoreOrganisation } from "@/Service/Api/ApiHooks/Organisation/useRestoreOrganisation";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

const OrganisationResetPage = () => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [checked, setChecked] = useState(false);
    const organisation_id = useAuthStore.getState().impersonatedOrgId ?? useAuthStore.getState().authUser?.organisation_id;
    const { data: organisation } = useGetOrganisation({ requestParams: { organisation_id: organisation_id as number } });

    const { mutateAsync, isPending } = useRestoreOrganisation();

    const handleReset = async () => {
        await mutateAsync({
            organisation_id: organisation_id as number,
            ResetOrgRequest: {
                organisation_id: organisation_id as number,
                is_fresh: checked
            }
        }, {
            onSuccess: () => {
                addToastMessage({
                    severity: 'success',
                    summary: 'Organisation reset',
                    detail: 'Organisation has been reset successfully'
                });
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title={'Organisation Reset'}
                    description={'Forseti Complaints Manager'}
                />
            </StyledWrap>

            <div className="page-item filter-cards">
                <Card>
                    <div className="flex align-items-center">
                        Are you sure you want to reset <strong
                            className="mx-2"> {organisation?.name} </strong> to default? This action will
                        erase all organisation data.
                    </div>

                    <div className="flex mt-4 justify-content-between align-items-center">
                        <div className="card flex gap-3 align-items-center justify-content-center">
                            Delete all existing data and reset to default data set
                            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)}/>
                        </div>
                        <div className="flex justify-content-end">
                            <Button onClick={handleReset} disabled={isPending} label="Reset" className="p-button-danger"/>
                        </div>
                    </div>


                </Card>
            </div>
        </div>
    );
};

export default OrganisationResetPage;
