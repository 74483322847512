/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 *
 * @export
 * @interface ComplaintAudit
 */
export interface ComplaintAudit {
    /**
     *
     * @type {number}
     * @memberof ComplaintAudit
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ComplaintAudit
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ComplaintAudit
     */
    complaint_id: number;
    /**
     *
     * @type {User}
     * @memberof ComplaintAudit
     */
    user?: User;
    /**
     *
     * @type {string}
     * @memberof ComplaintAudit
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof ComplaintAudit
     */
    log_name?: ComplaintAuditLogNameEnum;
    /**
     *
     * @type {string}
     * @memberof ComplaintAudit
     */
    event?: string;
    /**
     *
     * @type {Date}
     * @memberof ComplaintAudit
     */
    created_at: Date;
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof ComplaintAudit
     */
    properties: Array<Array<string>>;
}


/**
 * @export
 */
export const ComplaintAuditLogNameEnum = {
    Old: 'old',
    ComplaintAction: 'complaint_action',
    ComplaintContact: 'complaint_contact',
    ComplaintFos: 'complaint_fos',
    ComplaintCustomer: 'complaint_customer',
    ComplaintPoint: 'complaint_point',
    Complaint: 'complaint',
    ComplaintDecision: 'complaint_decision',
    ComplaintLetter: 'complaint_letter',
    ComplaintDocument: 'complaint_document',
    ComplaintEvidence: 'complaint_evidence',
    ComplaintOutcomes: 'complaint_outcomes',
    User: 'user',
    RootCause: 'root_cause',
    Organisation: 'organisation'
} as const;
export type ComplaintAuditLogNameEnum = typeof ComplaintAuditLogNameEnum[keyof typeof ComplaintAuditLogNameEnum];


/**
 * Check if a given object implements the ComplaintAudit interface.
 */
export function instanceOfComplaintAudit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "complaint_id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "properties" in value;

    return isInstance;
}

export function ComplaintAuditFromJSON(json: any): ComplaintAudit {
    return ComplaintAuditFromJSONTyped(json, false);
}

export function ComplaintAuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintAudit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'name': json['name'],
        'complaint_id': json['complaint_id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'description': json['description'],
        'log_name': !exists(json, 'log_name') ? undefined : json['log_name'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'created_at': (new Date(json['created_at'])),
        'properties': json['properties'],
    };
}

export function ComplaintAuditToJSON(value?: ComplaintAudit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'name': value.name,
        'complaint_id': value.complaint_id,
        'user': UserToJSON(value.user),
        'description': value.description,
        'log_name': value.log_name,
        'event': value.event,
        'created_at': (value.created_at.toISOString().substring(0,10)),
        'properties': value.properties,
    };
}

