// Routes config file

import InboxPage from '@/pages/InboxPage';
import LoginPage from '@/pages/LoginPage';
import TermsConditionsPage from '@/pages/TermsOfUsePage';
import AboutUsPage from '@/pages/AboutUsPage';
import CookiesPage from '@/pages/CookiesPage';
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage';
import FcaRmarPage from '@/pages/FcaRmarPage';
import OutboxPage from '@/pages/OutboxPage';
import KpiWorkInProgressPage from '@/pages/KpiWorkInProgressPage';
import KpiVolumesPage from '@/pages/KpiVolumesPage';
import KpiSummaryPage from '@/pages/KpiSummaryPage';
import FosInboxPage from '@/pages/Fos/FosInboxPage';
import FosOutboxPage from '@/pages/Fos/FosOutboxPage';
import DsarPage from '@/pages/DsarPage';
import MyProfilePage from '@/pages/MyProfilePage';
import KpiRootCausePage from '@/pages/KpiRootCausePage';
import CreateComplaintPage from '@/pages/Complaints/CreateComplaintPage';
import ViewComplaintPage from "@/pages/Complaints/ViewComplaintPage";
import LettersPage from "@/pages/LettersPage";
import { Navigate } from "react-router-dom";
import StartPage from "@/pages/StartPage";
import CustomersPage from "@/pages/CustomersPage";
import OrganisationUsersPage from "@/pages/OrganisationUsers/OrganisationUsersPage";
import OrganisationUserPage from "@/pages/OrganisationUsers/OrganisationUserPage";
import OrganisationAuditPage from "@/pages/OrganisationAudit/OrganisationAuditPage";
import RootCausePage from "@/pages/RootCausePage";
import DeletedComplaintsPage from "@/pages/DeletedComplaintsPage";
import OrganisationResetPage from "@/pages/OrganisationResetPage";

export const routes = {
    private: [
        {
            name: 'home',
            path: '/:orgId?',
            key: 'home',
            component: <StartPage/>
        },
        {
            name: 'inbox',
            path: '/:orgId?/inbox',
            key: 'inbox',
            component: <InboxPage/>
        },
        {
            name: 'outbox',
            path: '/:orgId?/outbox',
            key: 'outbox',
            component: <OutboxPage/>
        },
        {
            name: 'kpi-work-in-progress',
            path: '/:orgId?/kpi/work-in-progress',
            key: 'kpi-work-in-progress',
            component: <KpiWorkInProgressPage/>
        },
        {
            name: 'kpi-volumes',
            path: '/:orgId?/kpi/volumes',
            key: 'kpi-volumes',
            component: <KpiVolumesPage/>
        },
        {
            name: 'kpi-summary',
            path: '/:orgId?/kpi/summary',
            key: 'kpi-summary',
            component: <KpiSummaryPage/>
        },
        {
            name: 'dsar',
            path: '/:orgId?/dsar',
            key: 'dsar',
            component: <DsarPage/>
        },
        {
            name: 'customers',
            path: '/:orgId?/customers',
            key: 'customers',
            component: <CustomersPage/>
        },
        {
            name: 'organisation-audit',
            path: '/:orgId?/organisation/audit',
            key: 'organisation-audit',
            component: <OrganisationAuditPage />
        },
        {
            name: 'organisation-root-causes',
            path: '/:orgId?/organisation/root-causes',
            key: 'organisation-root-causes',
            component: <RootCausePage />
        },
        {
            name: 'organisation-deleted-complaints',
            path: '/:orgId?/organisation/deleted-complaints',
            key: 'organisation-deleted-complaints',
            component: <DeletedComplaintsPage />
        },
        {
            name: 'organisation-users',
            path: '/:orgId?/organisation/users',
            key: 'organisation-users',
            component: <OrganisationUsersPage />
        },
        {
            name: 'organisation-reset',
            path: '/:orgId?/organisation/reset',
            key: 'organisation-reset',
            component: <OrganisationResetPage />
        },
        {
            name: 'organisation-user',
            path: '/:orgId?/organisation/users/:userId',
            key: 'organisation-user',
            component: <OrganisationUserPage />
        },
        {
            name: 'letters',
            path: '/:orgId?/letters',
            key: 'letters',
            component: <LettersPage/>
        },
        {
            name: 'fos-inbox',
            path: '/:orgId?/fos-inbox',
            key: 'fos-inbox',
            component: <FosInboxPage/>
        },
        {
            name: 'fos-outbox',
            path: '/:orgId?/fos-outbox',
            key: 'fos-outbox',
            component: <FosOutboxPage/>
        },
        {
            name: 'complaint',
            path: '/:orgId?/complaint/:openComplaintId',
            key: 'complaint',
            component: <InboxPage/>
        },
        {
            name: 'complaints.create',
            path: '/:orgId?/complaints/create',
            key: 'complaints.create',
            component: <CreateComplaintPage/>
        },
        {
            name: 'complaints.view',
            path: '/:orgId?/complaints/:complaintId',
            key: 'complaints.view',
            component: <ViewComplaintPage />
        },
        {
            name: 'fca-rmar',
            path: '/:orgId?/fca-rmar',
            key: 'fca-rmar',
            component: <FcaRmarPage/>
        },
        {
            name: 'root-cause',
            path: '/:orgId?/root-cause',
            key: 'root-cause',
            component: <KpiRootCausePage/>
        },
        {
            name: 'my-profile',
            path: '/:orgId?/my-profile',
            key: 'my-profile',
            component: <MyProfilePage/>
        },
        {
            name: 'terms-of-use',
            path: '/:orgId?/terms-of-use',
            key: 'terms-of-use',
            component: <TermsConditionsPage/>
        },
        {
            name: 'about-us',
            path: '/:orgId?/about-us',
            key: 'about-us',
            component: <AboutUsPage/>
        },
        {
            name: 'cookies',
            path: '/:orgId?/cookies',
            key: 'cookies',
            component: <CookiesPage/>
        },
        {
            name: 'privacy-policy',
            path: '/:orgId?/privacy-policy',
            key: 'privacy-policy',
            component: <PrivacyPolicyPage/>
        }
    ],
    public: [
        {
            name: 'start',
            path: '/',
            component: <Navigate replace to={'/login'}/>
        },
        {
            name: 'login',
            path: '/login',
            component: <LoginPage/>
        },
        {
            name: 'terms-of-use',
            path: '/terms-of-use',
            component: <TermsConditionsPage/>
        },
        {
            name: 'about-us',
            path: '/about-us',
            component: <AboutUsPage/>
        },
        {
            name: 'cookies',
            path: '/cookies',
            component: <CookiesPage/>
        },
        {
            name: 'privacy-policy',
            path: '/privacy-policy',
            component: <PrivacyPolicyPage/>
        }
    ]
};
