/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     *
     * @type {number}
     * @memberof AuthUser
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AuthUser
     */
    organisation_id: number;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    organisation_name?: string;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    org_ext_id?: string | null;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    firstname?: string;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    middlename?: string | null;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    surname?: string | null;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    display_name: string | null;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    profile_photo_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    auth0_id: string;
    /**
     *
     * @type {string}
     * @memberof AuthUser
     */
    main_role: AuthUserMainRoleEnum;
    /**
     *
     * @type {boolean}
     * @memberof AuthUser
     */
    is_nova_admin: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AuthUser
     */
    permissions: Array<string> | null;
}


/**
 * @export
 */
export const AuthUserMainRoleEnum = {
    Inactive: 'inactive',
    ServiceAccount: 'service_account',
    ComplaintLogger: 'complaint_logger',
    ComplaintHandler: 'complaint_handler',
    TeamLeader: 'team_leader',
    TeamFinance: 'team_finance',
    SystemManager: 'system_manager',
    SystemAdmin: 'system_admin'
} as const;
export type AuthUserMainRoleEnum = typeof AuthUserMainRoleEnum[keyof typeof AuthUserMainRoleEnum];


/**
 * Check if a given object implements the AuthUser interface.
 */
export function instanceOfAuthUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organisation_id" in value;
    isInstance = isInstance && "display_name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "auth0_id" in value;
    isInstance = isInstance && "main_role" in value;
    isInstance = isInstance && "is_nova_admin" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function AuthUserFromJSON(json: any): AuthUser {
    return AuthUserFromJSONTyped(json, false);
}

export function AuthUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'organisation_id': json['organisation_id'],
        'organisation_name': !exists(json, 'organisation_name') ? undefined : json['organisation_name'],
        'org_ext_id': !exists(json, 'org_ext_id') ? undefined : json['org_ext_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'display_name': json['display_name'],
        'email': json['email'],
        'profile_photo_path': !exists(json, 'profile_photo_path') ? undefined : json['profile_photo_path'],
        'auth0_id': json['auth0_id'],
        'main_role': json['main_role'],
        'is_nova_admin': json['is_nova_admin'],
        'permissions': json['permissions'],
    };
}

export function AuthUserToJSON(value?: AuthUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'organisation_id': value.organisation_id,
        'organisation_name': value.organisation_name,
        'org_ext_id': value.org_ext_id,
        'title': value.title,
        'firstname': value.firstname,
        'middlename': value.middlename,
        'surname': value.surname,
        'display_name': value.display_name,
        'email': value.email,
        'profile_photo_path': value.profile_photo_path,
        'auth0_id': value.auth0_id,
        'main_role': value.main_role,
        'is_nova_admin': value.is_nova_admin,
        'permissions': value.permissions,
    };
}

